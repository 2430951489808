import React, { useState } from 'react';
// import 'react-calendar/dist/Calendar.css'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
// import Calendar from 'react-calendar';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { CenterFocusStrong } from '@mui/icons-material';
import Slider from 'react-slick';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Button } from '@mui/material';
import pdf2022_1 from 'assests/Pdf/Results/CLASS_10_2023_RESULTS.pdf';
import pdf2022_2 from 'assests/Pdf/Results/CLASS_12_2023_RESULTS.pdf';
import pdf3 from 'assests/Pdf/JanuaryEntranceTestSchedule.pdf';
import pdf4 from 'assests/Pdf/MARCH_ENTRANCE_TEST_SCHEDULE.pdf';
import pdf5 from 'assests/Pdf/MARCH_ENTRANCE_TEST_SCHEDULE.pdf';
import pdf6 from 'assests/Pdf/enterance/Entrance_test.pdf';
// import pdf7 from 'assests/Pdf/enterance/entrance_test_January.pdf';
import pdf8 from 'assests/Pdf/enterance/march_entrance.pdf';
import pdf1 from 'assests/Pdf/Results/CLASS_10_2024_RESULTS.pdf';
import pdf2 from 'assests/Pdf/Results/CLASS_12_2024_RESULTS.pdf';



const mockAnn = [
  // {
  //   feedback:
  //     'The school office will be closed from 29.12-2023 to 02.01.2024.',
  // },
  // {
  //     feedback:
  //       'School Office will remain closed on 26.04.2024 to 07.05.2024.',
  //   },
  // {
  //   feedback:
  //   'Entrance Test Schedule for March 2024.',
  //   name: '', 
  //   title: 'Click here',
  //   target: '_blank',
  //   href: pdf8,
  // },
  // {
  //   feedback:
  //   'Entrance Test Schedule for January 2024',
  //   name: '', 
  //   title: 'Click here',
  //   target: '_blank',
  //   href: pdf7,
  // },
  // {
  //   feedback:
  //   'For Entrance Test Schedule of 2024-2025.',
  //   name: '', 
  //   title: 'Click here',
  //   target: '_blank',
  //   href: pdf6,
  // },
  // {
  //   feedback:
  //     'Registration for admission to Montessori, Kindergarten, Classes 1- 9 and 11 for the academic year 2024 - 25 is now open.',
  //   name: '', 
  //   title: 'Click here',
  //   href:'/admission-criteria/',
  // },
  
  // {
  //   feedback:
  //   "For March Entrance Test Schedule of 2023 -2024. Click here.",
  //   name: '',
  //   title: 'Click here',
  //   href:pdf5,
  // },

  // {
  //   feedback:
  //     'School Office will remain closed on 28.12.2022 to 01.01.2023.',School Office will remain closed on 28.12.2022 to 01.01.2023.
  //   name: '',
  //   title: '',
  //   href:'',
  // },
  // {
  //   feedback:
  //   "For March Entrance Test Schedule of 2023 -2024. Click here.",
  //   name: '',
  //   title: 'Click here',
  //   href:pdf4,
  // },
  // {
  //   feedback:
  //     'Registration for admission to Classes 1, 2, 4, 5, 9 and 11 for the academic year 2023 - 24 is now open.',
  //   name: '',
  //   title: 'Click here',
  //   href: '/admission-criteria',
  //   avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
  // },
  // {
  //   feedback:
  //     'For Entrance Test Schedule of 2023 -2024.',
  //   name: '',
  //   title: 'Click here',
  //   href:pdf4,
  // },
  // {
  //   feedback:
  //   "For March Entrance Test Schedule of 2023 -2024. Click here.",
  //   name: '',
  //   title: 'Click here',
  //   href:pdf5,
  // },
  // {
  //   feedback:
  //     'School Office will remain closed on 28.12.2022 to 01.01.2023.',
  //   name: '',
  //   title: '',
  //   href:'',
  // },
 
  {
    feedback:
      'AISSE Class 10 Results 2023-24',
    name: '',
    title: 'Click here',
    avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
    href:pdf1,
  },

  {
    feedback:
      'AISSCE Class 12 Results 2023-24',
    name: '',
    title: 'Click here',
    avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
    href:pdf2,
  },

  // {
  //   feedback:
  //     'AISSE Class 10 Results 2022-23',
  //   name: '',
  //   title: 'Click here',
  //   avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
  //   href:pdf2022_1,
  // },

  // {
  //   feedback:
  //     'AISSCE Class 12 Results 2022-23',
  //   name: '',
  //   title: 'Click here',
  //   avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
  //   href:pdf2022_2,
  // },
 
  // {
  //   feedback:
  //     'Registration for admission to Montessori 1 and Kindergarten 1 for the academic year 2023 - 24 is now open.',
  //   name: '',
  //   title: 'Click here',
  //   href: '/admission-criteria',
  //   avatar: 'https://assets.maccarianagency.com/avatars/img2.jpg',
  // },
  
  // {
  //   feedback:
  //     'Guidelines for the New Admissions for the year 2023-24',
  //   name: '8th October, 2021',
  //   title: 'Click here',
  //   href: '/admission-criteria',
  //   avatar: 'https://assets.maccarianagency.com/avatars/img3.jpg',
  // },


];



const Partners = () => {
  const theme = useTheme();
  const [value, onChange] = useState(new Date());
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sliderOpts = {
    dots: true,
    infinite: true,
     speed: 200,
    // sleep: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    // dotRadius:2.5,
  };
 
  const [date, setDate] = useState(new Date())
  
  const [viewPortEntered, setViewPortEntered] = useState(false);
  const setViewPortVisibility = (isVisible) => {
    if (viewPortEntered) {
      return;
    }

    setViewPortEntered(isVisible);
  };

  return (
 
       
      
      
       <Grid
        item xs={24} md={12}
        container
        justifyContent="center"
        alignItems="center"
        // border={'1px solid black'}
        // borderRadius = '15px'
        // backgroundColor = '#659BDF' 
     
        
        
        // style={{ borderLeft: "1px dotted grey" ,}}
        // xs={12}
        // md={6}
        // sx={{
        //   display: { xs: 'flex', md: 'flex' },
        // }}
      >

 
   
    <Box 
    // display={'flex'} flexDirection={'column'} 
      alignItems={'center'}
      xs={10}
      md={5}>
        <Typography sx={{ fontWeight: 700 }} variant={'h4'} align='center' color={'white'}>
            Announcement
          </Typography>
      {/* <Box marginBottom={1} xs={12}  md={6} >
      
      </Box> */}
      <Box maxWidth={350} width={1}  xs={10}  md={5} borderColor={'blue'}>
        <Slider {...sliderOpts} dotRadius={2.5} xs={10}  md={5} >
          {mockAnn.map((item, i) => (
            <Box key={i}>
              <Box
                width={1}
                height={1}
                component={Card}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                boxShadow={0}
                xs={12}
                md={6}
                sx={{ bgcolor: 'transparent', backgroundImage: 'none' }}
              >
                <CardContent>
                  <Typography align={'center'} variant={'h6'} color={'white'} >
                    {item.feedback}
                  </Typography>
                 
                  
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ paddingBottom: 0 }}>
                  <ListItem component="div" disableGutters sx={{ padding: 0 }}>
                    
                    {/* <ListItemText
                      sx={{ margin: 0 }}
                    /> */}
                     <Button component="a" href={item.href} target="_blank" >
                  <Typography
                  variant={'p'}
                  fontWeight={300}
                  align={'center'}
                  color={'white'}
                  
                >
                  {item.title}
                </Typography>
                </Button>
                  </ListItem>
                
                </CardActions>
                <br />
               
              </Box>
            </Box>
          ))}
        </Slider>
      {/* </Box> */}
    </Box>
    </Box>
      </Grid>
  );
};

export default Partners;