import React from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import p1 from 'assests/Photos/highschool.jpg';
// import p2 from 'assests/Photos/juniorschool.jpg';
import p3 from 'assests/Photos/middleschool.jpg';
import p4 from 'assests/Photos/kindergarden.jpg';
import p5 from 'assests/Photos/Mont.jpg';

import p6 from 'assests/Photos/knowmore/3B/IMG_20221007_090726.jpg'



const mock = [
  {
    media: p5,
    title: 'Montessori',
    subtitle:
      '',
      href:'/montessori',
  },
  {
    media: p4,
    title: 'Kindergarten',
    subtitle:
      '',
    href:'/kindergarten',
  },
  {
    media: p6,
    title: 'Primary School (1-5)',
    subtitle:
      '',
    href:'/junior-school',
  },
  {
    media: p3,
    title: 'Middle School (6-8)',
    subtitle:
      '',
      href:'/middle-school'
  },
  {
    media: p1,
    title: 'Senior School (9-12)',
    subtitle:
      '',
    href:'/secondary-school',
  },
];

const Features = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sliderOpts = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: isMd ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
        >
          
        </Typography>
        <Typography fontWeight={700} variant={'h4'} align={'center'}>
          Your Journey Begins Here..
        </Typography>
      </Box>
      <Box
        data-aos={'fade-up'}
        maxWidth={{ xs: 420, sm: 620, md: 1 }}
        margin={'0 auto'}
      >
        <Slider {...sliderOpts}>
          {mock.map((item, i) => (
            <Box key={i} padding={{ xs: 1, md: 2, lg: 3 }}>
              <Box
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  display={'flex'}
                  flexDirection={'column'}
                  sx={{ backgroundImage: 'none' }}
                >
                  <CardMedia
                    title={item.title}
                    image={item.media}
                    sx={{
                      position: 'relative',
                      height: { xs: 240, sm: 340, md: 280 },
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      component={'svg'}
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 1921 273"
                      sx={{
                        position: 'absolute',
                        width: '100%',
                        left: 0,
                        bottom: 0,
                        right: 0,
                        zIndex: 1,
                      }}
                    >
                      {/* <polygon
                        fill={theme.palette.background.paper}
                        points="0,273 1921,273 1921,0 "
                      /> */}
                    </Box>
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant={'h6'}
                      gutterBottom
                      align={'left'}
                      sx={{ fontWeight: 700 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography align={'left'} color={'text.secondary'}>
                      {item.subtitle}
                    </Typography>
                  </CardContent>
                  <Box flexGrow={1} />
                  <CardActions sx={{ justifyContent: 'flex-end' }}>
                    <Button
                     href={item.href}>
                      Know more
                    </Button>
                  </CardActions>
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default Features;