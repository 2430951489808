import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Link from'@mui/material/Link';
import { Button } from '@mui/material';
// import p1 from 'assests/Photos/KP-gopal.jpg';
import p2 from 'assests/Photos/Nikitaa-kalro.jpg';
import p3 from 'assests/Photos/Messages/principal_administration_ms_sheeja_manoj.jpg';
// import p6 from 'assests/Photos/fes/SHEEJAMANOJ.jpg'
import p5 from 'assests/Photos/Messages/IMGL08311.jpg'
// import p4 from 'assests/Photos/yml47Ph.jpg';

const Benefits = () => {

  const [config, setConfig] = useState({ base_image_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_url}/home/home-static/static-images/KP-gopal.webp`;
const p4 = `${config.base_image_url}/home/home-static/messages/Chairman.webp`;
const p6 = `${config.base_image_url}/home/home-static/messages/Sheeja.webp`;

const mock = [
  {
    title: 'Dr. K.P. Gopalkrishna',
    href: '/message-section/#Gopalkrishna',
    subtitle:
      'Chairman, NPS Group',
      Message : 'As the world approaches a.....',
    icon: p1,
  },
  {
    title: 'Dr. Shivshankar',
    href: '/message-section/#Shivshankar',
    subtitle:
      'Chairman, NPS Yeshwanthpur',
      Message : 'There is no letter that.....',
    icon: p4,
  },
  {
    title: 'Ms. Sheeja Manoj',
    href: '/message-section/#Sheeja',
    subtitle:
      'Principal ',
      Message : 'Every bud conceals fragrance.....',
    icon: p6,
  },
  // {
  //   title: 'Ms Nikitaa Kalro',
  //   href: '/message-section/#Nikitaa',
  //   Message : 'Walt Disney once said You can.....',
  //   subtitle:
  //     'Principal Academics',
  //   icon: p5,
  // },
];

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
        >
          
        </Typography>
        <Typography fontWeight={700} variant={'h4'} align={'center'}>
          Messages
        </Typography>
      </Box>
      <Grid container spacing={2} align={'center'} justifyContent={'center'}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <div>
            <Box
              justifyContent={'center'}
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Button
                  href={item.href}>  
                <Box
                  component={Avatar}
                  width={170}
                  height={170}
                  marginBottom={2}
                  src={item.icon}
                
                /></Button>
              

                {/* <Button
                  component={Avatar}
                  width={130}
                  height={130}
                  marginBottom={2}
                  src={item.icon}
                  href={item.href}
                /> */}
          
                <Typography
                  variant={'h6'}
                  gutterBottom
                  fontWeight={500}
                  align={'center'}
                  href={item.href}
                >
                  {item.title}
                </Typography>
          
                <Typography align={'center'} color="text.secondary">
                  {item.subtitle}
                </Typography>
                <Button 
                href = {item.href}>
                <Typography align={'justify'} color="text.secondary" href={item.href}>
                  {item.Message}
                </Typography>
                </Button>
              </Box>
            </Box>
            </div>
          </Grid>
        ))} 
      </Grid>
     
    </Box>
  );
};

export default Benefits;
